<template>
  <div class="account-header">
    <slot name="breadcrumb">
      <Breadcrumb :items="[{label: $t('Account')}]"/>
    </slot>
    <div class="acc-container d-flex align-items-center" v-if="account">
      <div class="acc-avatar">
        {{ account.avatarChar }}
      </div>
      <div class="acc-info">
        <div class="acc-name">{{ account.displayName }}</div>
        <div class="acc-wallet d-flex align-items-center">
          <div class="acc-money mr-2"><strong class="mr-1">{{ $t("Account balance") }}:</strong>{{this.money}}<img src="../../assets/images/icon/money.svg" width="16px"/></div>
          <div class="acc-point pr-2"><strong class="mr-1">{{ $t("Point") }}:</strong>{{this.point}}</div>
        </div>
      </div>
      <a href="#" style="margin-top: -5px" @click.prevent="$router.push({name: 'AccountHistory'})"><img src="../../assets/images/icon/history_log.svg" /></a>
    </div>
  </div>
</template>

<script>
import profileService from "@/services/profileService";

export default {
  name: "AccountHeader",
  data() {
    return {
      account: this.$store.getters.account,
      money: 0,
      point: 0
    }
  },
  async created() {
    const response = await profileService.index();
    if (!response || response.error)
      return;
    
    const data = response.data;
    this.money = data.money.$toString();
    this.point = data.point.$toString();
  }
}
</script>

<style scoped lang="scss">
.account-header {
  padding: 15px;
  background-color: hsla(0, 0%, 93.3%, .5);

  .acc-container {
    padding: 10px 0;

    .acc-avatar {
      display: block;
      margin-right: 15px;
      width: 56px;
      height: 56px;
      background-color: #fab70f;
      border-radius: 50%;
      text-align: center;
      line-height: 64px;
      color: #fff;
      font-weight: 900;
      font-size: 38px;
    }

    .acc-info {
      flex: 1;

      .acc-name {
        font-size: 1.2em;
        font-weight: 700;
      }

      .acc-id {
        font-size: 1.1em;
      }

      .acc-wallet {
        font-size: 13px;
        
        .acc-money {
          img {
            float: right;
            margin-left: 3px;
          }
        }
      }
    }
  }
}
</style>