import httpHelper from "@/helpers/httpHelper";

const profileService = {
    async index() {
        return await httpHelper.get("/WebApi/Profile/Index");
    },
    async account() {
        return await httpHelper.get("/WebApi/Profile/Account");
    },
    async update() {
        return await httpHelper.get("/WebApi/Profile/Update");
    },
    async updateEmail(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/Profile/UpdateEmail", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
    async updatePhone(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/Profile/UpdatePhone", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
    async changePassword(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/Profile/ChangePassword", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
    async updateInviteCode(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/Profile/UpdateInviteCode", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
    async getLogs(data) {
        return await httpHelper.get("/WebApi/Profile/GetLogs", data);
    },
};

export default profileService;
