<template>
  <div class="page page-update-email">
    <AccountHeader>
      <template #breadcrumb>
        <Breadcrumb :items="[{label: $t('Account'), click: () => $router.push({name: 'Account'})}, {label: $t('Change password')}]"/>
      </template>
    </AccountHeader>
    <div class="p-3">
      <h1>{{$t("Change password")}}</h1>
      <div class="description mb-3" v-html="$t('Change password note')">
      </div>
      <b-alert :show="requireCode" v-html="$t('Secure code has been sent to your email')" variant="warning"></b-alert>
      <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-input type="password" v-model="formData.currentPassword" :disabled="loading" :placeholder="$t('Enter a current password')"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }" vid="password" :name="$t('New password')">
            <b-input type="password" v-model="formData.newPassword" :disabled="loading" :placeholder="$t('Enter a new password')"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }">
            <b-input type="password" v-model="formData.confirmNewPassword" :disabled="loading" :placeholder="$t('Enter a confirm new password')"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
      </ValidationObserver>
      <b-button variant="primary" class="text-uppercase font-weight-bold" block @click="onSubmit()">{{$t("Change password")}}</b-button>
    </div>
    <LoadingModal :show="loading" />
  </div>  
</template>

<script>
import AccountHeader from "@/components/headers/AccountHeader";
import Template from "@/views/game/giftcode/Template";
import profileService from "@/services/profileService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "ChangePassword",
  components: {Template, AccountHeader},
  data() {
    return {
      loading: false,
      requireCode: false,
      formData: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      }
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await profileService.changePassword(this.formData, await this.$recaptcha('changePassword'))
      this.loading = false;

      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      await dialogHelper.alert(response.message, "success");
      await this.$router.push({name: "Account"})
    }
  }
}
</script>

<style scoped lang="scss">
.page-update-email {
  h1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3px;
  }
}
</style>